<template>
  <div class="content">
    <div>
      <el-form ref="staffRef" :inline="true" size="medium" :model="dataForm" label-width="100px">
        <el-form-item label="注册日期：">
          <el-date-picker
              :clearable="false"
              v-model="timeRegister"
              type="daterange"
              @change="getTimeRegister"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <!-- <el-form-item label="充值日期：">
          <el-date-picker
              v-model="timeRecharge"
              @change="getTimeRecharge"
              type="daterange"
              :clearable="false"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->

        <el-button type="primary" @click="getTable" size="medium">查询</el-button>
        <el-button plain @click="getReset" size="medium">重置</el-button>
      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="gameId" label="游戏ID" align="center"></el-table-column>
        <el-table-column prop="gameName" label="游戏名称" align="center"></el-table-column>
        <el-table-column prop="roleCount" label="角色数" align="center"></el-table-column>
        <el-table-column prop="dataIp" label="IP" align="center"></el-table-column>
        <el-table-column prop="rechargeCount" label="充值数" align="center"></el-table-column>
        <el-table-column prop="rechargeAccount" label="充值金额" align="center"></el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import {dataStatisticsRegistrationStatisticsList} from "../../service/moudules/promotionLinkApi";
import moment from "moment";
export default {
  name: "recharge",
  mixins: [loadingStatusMixin],
  data (){
    return {
      dataForm: {
        registerStartDate: '', //注册开始时间
        registerEndDate: '', //注册结束时间
        rechargeStartDate: '', //充值开始时间
        rechargeEndDate: '', //充值结束时间
      },
      tableData: [],
      // timeRegister: [moment(new Date()).subtract(1,'day').format('yyyy-MM-DD'),moment(new Date()).format('yyyy-MM-DD')],
      // timeRecharge: [moment(new Date()).subtract(1,'day').format('yyyy-MM-DD'),moment(new Date()).format('yyyy-MM-DD')],
      timeRegister: [],
      timeRecharge: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

    }
  },
  mounted() {
    let beforeDay = moment(new Date()).subtract(30,'day').format('yyyy-MM-DD 00:00:00');
    let whenDay = moment(new Date()).format('yyyy-MM-DD 23:59:59');
    console.log(beforeDay,whenDay)
    this.dataForm = {
      registerStartDate: beforeDay, //注册开始时间
      registerEndDate: whenDay, //注册结束时间
    };
    this.timeRegister=[beforeDay,whenDay]
    this.getTable();
  },
  methods:{
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsRegistrationStatisticsList(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getTimeRecharge(val) {
      if (val.length > 0) {
        let [x, y] = val;
        this.dataForm.rechargeStartDate = x;
        this.dataForm.rechargeEndDate = y;
      }
    },
    getTimeRegister(val) {
      console.log('val',val)
      if (val && val.length > 0) {
        let [x, y] = val;
        this.dataForm.registerStartDate = x+' 00:00:00';
        this.dataForm.registerEndDate = y+' 23:59:59';
      } else {
        this.dataForm.registerStartDate = '';
        this.dataForm.registerEndDate = '';
      }
    },
    getReset() {
      /*let beforeDay = moment(new Date()).subtract(1,'day').format('yyyy-MM-DD');
      let whenDay = moment(new Date()).format('yyyy-MM-DD');
      this.dataForm = {
        registerStartDate: beforeDay, //注册开始时间
        registerEndDate: whenDay, //注册结束时间
        rechargeStartDate: beforeDay, //充值开始时间
        rechargeEndDate: whenDay, //充值结束时间
      };
      this.timeRegister = [beforeDay,whenDay];
      this.timeRecharge = [beforeDay,whenDay];*/
      this.dataForm = {
        registerStartDate: '', //注册开始时间
        registerEndDate: '', //注册结束时间
        rechargeStartDate: '', //充值开始时间
        rechargeEndDate: '', //充值结束时间
      };
      this.timeRegister = [];
      this.timeRecharge = [];
      this.getTable();
    }
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>